
import TimelineComponent from '../components/TimelineComponent/TimelineComponent';

function Home() {

    return (
        <>
            <TimelineComponent/>
        </>
    );
}

export default Home;