import React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
} from "antd";
import TeamMembers from '../components/Team/TeamMembers';
function Team() {

    return (
        <>
                <TeamMembers></TeamMembers>
        </>
    );
}

export default Team;
