import { useEffect } from "react";
//import { useSubHeaderContext } from "./SubHeaderContext";

export const SubHeader = ({ children }) => {
   // const { updateSubHeader, subHeader } = useSubHeaderContext();

    //useEffect(() => updateSubHeader(children), [children]);

    return null;
};
